.interface {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
}

#svg_scroll_down svg {
    max-height: 10vh;
    width: auto;
    margin: auto;
    pointer-events: auto;
    cursor: pointer;
    stroke: rgb(0, 59, 79);
}

#svg_scroll_down svg:hover {
    scale: 1.1;
    stroke: rgb(0, 117, 155);
}

.pointer {
    pointer-events: auto;
    cursor: pointer;
}

#menu_intro {
    display: flex !important;
    justify-content: space-between;
    flex-direction: row;
    align-items: end;
    bottom: 0;
    width: 100%;
    text-align: center;
    position: fixed;
}

#skip_intro {
    background: none;
    padding: 10px;
    border-radius: 20px;
    border-style: solid;
    border-color: rgb(0, 59, 79);
    color: rgb(0, 59, 79);
    margin-left: 10px;
    margin-bottom: 10px;
    font-size: 10px;
    border-width: thin;
}

#skip_intro:hover {
    border-color: rgb(0, 117, 155);
    color: rgb(0, 117, 155);
}