body {
    margin: 0px;
}

.leva-container {
    width: 350px;
    position: absolute;
    top: 0;
    right: 0;
}

.main3D {
    background-color: rgba(203, 213, 225, 0.2);
}

.section3D {
    width: 100%;
    height: 100vh;
    position: relative;
}

.canvas3D {
    width: 100%;
    height: 100vh;
    background-color: transparent;
}