@font-face {
    font-family: 'rusticOutline';
    src: url('rusticOutline.woff2') format('woff2'), url('rusticOutline.ttf') format('truetype');
    font-display: block;
}

@font-face {
    font-family: 'rusticLine';
    src: url('rusticLineLight.woff2') format('woff2'), url('rusticLineLight.ttf') format('truetype');
    font-display: block;
}

@font-face {
    font-family: 'rounded';
    src: url('rounded.woff2') format('woff2'), url('rounded.ttf') format('truetype');
    font-display: block;
}

html {
    background-color: #ebebeb;
    font-family: 'rounded';
    font-size: 1em;
}

.page-wrapper {
    min-height: 100vh;
    display: grid;
    grid-template-rows: auto 1fr auto;
}

div.header,
div.footer {
    background-color: #693300;
    color: #fff;
    text-align: center;
}

div.footer {
    padding: 10px;
}

.hidden {
    display: none;
}

div.menu {
    cursor: pointer;
}

ul.nav {
    flex-direction: column;
    list-style: none;
    padding: 0px;
    margin: 0px;
}

li.nav {
    padding-bottom: 0.5em;
    font-size: 0.9em;
    cursor: pointer;
}

li.nav:hover {
    color: #bbb;
}

li.nav.logo img {
    height: 15vh;
    padding-top: 0.5em;
}

@media screen and (min-width: 1200px) {
    div.menu {
        display: none;
    }

    ul.nav {
        display: flex !important;
        justify-content: space-evenly;
        flex-direction: row;
        align-items: center;
    }
}

.nav a:link {
    text-decoration: none;
    color: #fff;
}

.nav a:visited {
    color: #fff;
}
.nav a:hover {
    color: #aaa;
}

button {
    cursor: pointer;
}

.outlet {
    margin: 0px;
    margin-right: auto;
    margin-left: auto;
    max-width: min(900px, 90vw);
}

h1 {
    font-family: 'rusticOutline';
    text-align: center;
    font-size: 3em;
    line-height: 0.8em;
}

h2 {
    font-family: 'rusticLine';
    padding-top: 50px;
    font-size: 2.5em;
    line-height: 0.7em;
}

h3 {
    font-family: 'rusticLine';
    font-size: 1.5em;
    line-height: 0.7em;
}

img.picture {
    max-width: 100%;
    display: block;
    margin-right: auto;
    margin-left: auto;
    text-align: center;
    padding-bottom: 5px;
    border-radius: 2em;
}

p.picture {
    padding-top: 0;
    margin-top: 0;
    text-align: center;
    font-style: italic;
    font-weight: bold;
    color: #656565;
}

p.table {
    padding-bottom: 0;
    font-style: italic;
}

td.table {
    padding: 10px;
}
tr:nth-child(even) {
    background-color: #d9d9d9;
}

a.footer {
    text-decoration: none;
    color: #fff;
}

/* special styling for the pictures on the home page */
img.pic.home {
    border-radius: 50%;
}

/* loading... styles */
#loading {
    line-height: 100%;
    height: 100%;
    text-align: center;
    width: auto;
    margin: auto;
}

/* Language buttons */
.lang-flag {
    width: 4vh;
    height: 4vh;
    border-radius: 50%;
    border: none;
    margin: 5px;
    background-size: cover;
}

.en {
    background-image: url("flag_en.png");
}

.fr {
    background-image: url("flag_fr.png");
}